import React, {useEffect} from 'react';
import Customer from "../body/customer/Customer";
import Tip from "../body/tip/Tip";
import {useParams} from "react-router";
import axios from "axios";

interface ICustomer {
    name: string
    place: string
    imageUrl: string
    uuid: string
}

interface IProps {
    amount: number
    handleAmount: (e: number) => void
    checked: boolean
    handleChecked: () => void
    customer: ICustomer
}

const Body: React.FC<IProps> = ({amount, handleAmount, checked, handleChecked}) => {

    // @ts-ignore
    let {uuid} = useParams();
    const [customer, setCustomer] = React.useState({name:"", place: "", imageUrl:"", uuid:""});
    useEffect(() => {
        axios
            // .get("http://localhost:8080/gardadoma?uuid=" + uuid)
            .get("https://nocash-319015.ew.r.appspot.com/person?uuid=" + uuid)
            .then((response) => {
                console.log("Response: ", response);
                setCustomer(response.data);
            })
            .catch((error) => {
                console.log("No customers: ", error);
            });
    }, [])
    return (
        <div
            className="container customer-rounded sm:max-w-screen-lg md:max-w-screen-xl
            lg:w-10/12
            flex-row pb-6">
            <Customer name={customer.name} place={customer.place} imageUrl={customer.imageUrl} uuid={customer.uuid}/>
            <Tip amount={amount} handleAmount={handleAmount} uuid={customer.uuid} checked={checked}
                 handleChecked={handleChecked}/>
        </div>
    )

}

export default Body
