import React, {useEffect, useRef} from "react";
import { default as NumberFormat } from 'react-number-format';

interface IProps {
    amount: number
    handleAmount: (amount: number) => void
    id: string
}

const TipNumberFormat: React.FC<IProps> = ({amount, handleAmount, id}) => {
    const tipBtnRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (tipBtnRef.current) {
            tipBtnRef.current.disabled = amount === 0;
        }
    }, [amount]);

    return (
        <NumberFormat id={id} value={amount === 0 ? '' : amount} suffix={' €'} onValueChange={(e) => {
            handleAmount(e.value === '' ? amount : parseFloat(e.value) as number);
        }} placeholder="Enter amount (€)"  className="border-bottom-color text-center w-40 sm:w-60 outline-none
            p-3 bg-transparent
            border-b-2 text-white font-sans text-md sm:text-2xl pt-1 border-l-0
            border-t-0 border-r-0 rounded-none focus-within:ring-0 input-appearance "
        />
    );
};

export default TipNumberFormat;