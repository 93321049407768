import React from 'react';
import logo from '../../img/svg/logo.svg';
import styles from './styles.module.css';

interface IProps {
    toggleLanguage: any;
    language: string;
}

const Header: React.FC<IProps> = ({language, toggleLanguage}) =>
    <div className={styles.header}>
        <img src={logo} alt="NoCash"/>
    </div>
export default Header

