import React, {useState, useEffect} from 'react';
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';
import axios from "axios";
import {PaymentRequest, StripeElementClasses, StripePaymentRequestButtonElementOptions} from "@stripe/stripe-js";

interface IProps {
    amount: number
    handleSuccess: () => void
    handleError: (payload: any) => void
}

const AppleGooglePayCheckoutForm: React.FC<IProps> = ({amount, handleError, handleSuccess}) => {
    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        let isMounted = true; // флаг для отслеживания монтирования компонента
        const source = axios.CancelToken.source(); // источник для отмены запроса axios

        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'AU',
                currency: 'eur',
                total: {
                    label: 'Total amount',
                    amount: amount * 100
                }
            });

            pr.canMakePayment().then(result => {
                if (isMounted) { // проверяем, что компонент все еще смонтирован
                    if (result) {
                        setPaymentRequest(pr);
                    }
                }
            });

            axios
                .post("https://nocash-319015.ew.r.appspot.com/payment", {
                    amount: amount == undefined ? 0 : amount,

                }, {
                    cancelToken: source.token // передаем токен отмены
                })
                .then((response) => {
                    if (isMounted) { // проверяем, что компонент все еще смонтирован
                        setClientSecret(response.data);
                    }
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled', error.message);
                    } else {
                        console.log("Payment failed: ", error);
                    }
                });
        }

        return () => {
            isMounted = false; // при размонтировании компонента устанавливаем флаг в false
            source.cancel('Operation canceled by the user.'); // отменяем запрос axios
        };
    }, [stripe]);

    console.log("paymentRequest: ", paymentRequest);

    if (paymentRequest && stripe) {
        paymentRequest.on('paymentmethod', async (ev: any) => {
            const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
                clientSecret,
                {payment_method: ev.paymentMethod.id},
                {handleActions: false}
            );

            if (confirmError) {
                ev.complete('fail');
            } else {
                ev.complete('success');
                // @ts-ignore
                if (paymentIntent.status === "requires_action") {
                    const payload = await stripe.confirmCardPayment(clientSecret);
                    if (payload.error) {
                        handleError(payload);
                    } else {
                        handleSuccess();
                    }
                } else {
                    handleSuccess();
                }
            }
        });
    }

    if (paymentRequest) {
        const cssClasses: StripeElementClasses = {
            base: "apple-pay-border"
        }
        const options: StripePaymentRequestButtonElementOptions = {
            classes: cssClasses,
            paymentRequest: paymentRequest,
            style: {
                paymentRequestButton: {
                    theme: 'light'
                }
            }
        }
        return <div className="bg-white rounded-full" style={{marginTop: "8px"}}>
            <PaymentRequestButtonElement options={options}/>
        </div>
    } else {
        return <div/>;
    }
}


export default AppleGooglePayCheckoutForm;
