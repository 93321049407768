import React from "react";
import {Link} from "react-router-dom";

interface IProps {
    uuid: string,
    amount: number
}

const CardPayButton: React.FC<IProps> = ({uuid, amount}) => {
    return (
        <Link to={`/person/payment/${uuid}`}>
            <button id={'enablerBtn'}
                    className="lg:w-56 font-bold py-2 px-4 rounded-full text-white"
                    disabled={amount === 0 || amount === undefined}
            >
                Tip
            </button>
        </Link>
    );
}

export default CardPayButton;
